<script lang="ts" setup>
const { playersActivity, fetchedPlayersActivity } = useLatestWinners();
const documentVisibleState = useDocumentVisibility();
</script>

<template>
	<div v-if="playersActivity.length" class="list-winners-wrapper" data-tid="last-winners">
		<MHomeSectionHeader title="recent winners" />
		<ClientOnly>
			<AHorizontalScroll shadowEffect>
				<div v-if="documentVisibleState === 'hidden'" class="box-winners">
					<TransitionGroup name="list-transform">
						<MRecentGameCard
							v-for="(item, index) in fetchedPlayersActivity"
							:key="item.id"
							class="card-item"
							:data-tid="`last-winners-card-${index}`"
							:item="item"
						/>
					</TransitionGroup>
				</div>
				<div v-if="documentVisibleState === 'visible'" class="box-winners">
					<TransitionGroup name="list-transform">
						<MRecentGameCard
							v-for="(item, index) in playersActivity"
							:key="item.id"
							class="card-item"
							:data-tid="`last-winners-card-${index}`"
							:item="item"
						/>
					</TransitionGroup>
				</div>
			</AHorizontalScroll>
		</ClientOnly>
	</div>
</template>

<style scoped lang="scss">
div.list-winners-wrapper {
	display: flex;
	flex-direction: column;
	gap: 16px;
	overflow: hidden;

	&:deep(img) {
		pointer-events: none;
	}
}

.box-winners {
	display: flex;
	gap: 8px;
}

.flip-list-move {
	transition: transform 0.8s ease;
}

.list-transform-move {
	transition: transform 0.8s;
}

.list-width-enter-active,
.list-width-leave-active {
	transition:
		min-width 0.8s,
		width 0.8s,
		opacity 0.8s;
}
.list-width-enter-from,
.list-width-leave-to {
	opacity: 0;
	width: 0 !important;
	min-width: 0 !important;
	max-width: 0 !important;
}

.card-item {
	width: 190px;
}

.cards {
	$self: &;
	margin-top: 48px;
	position: relative;
	z-index: 5;

	@include media-breakpoint-down(md) {
		width: calc(100% + 15px);
		margin-top: 40px;
	}

	&-container {
		display: flex;
		position: relative;
		padding-top: 20px;
	}
}
</style>
